import { FC, lazy } from 'react';
import { AffiliatePermissionsEnum } from 'enums/accessTemplates';

import { IRoute } from 'pages/utils';
import BlankOutlet from 'components/common/BlankOutlet';
import Redirect from 'components/common/Redirect';

const routes: IRoute[] = [
  {
    path: '/partners',
    element: lazy(() => import('./Partners')),
    permissions: [AffiliatePermissionsEnum.SUPER_ADMIN],
  },
  {
    path: '/payment-methods',
    element: lazy(() => import('./PaymentMethods')),
    permissions: [AffiliatePermissionsEnum.SUPER_ADMIN],
  },
  {
    path: '/payment-methods/:paymentMethodId',
    element: lazy(() => import('./PaymentMethods/PaymentMethod')),
    permissions: [AffiliatePermissionsEnum.SUPER_ADMIN],
    routes: [
      {
        path: '',
        element: (() => <Redirect to="main" />) as FC,
      },
      { path: 'main', element: lazy(() => import('./PaymentMethods/PaymentMethod/Main')) },
      { path: 'currencies', element: lazy(() => import('./PaymentMethods/PaymentMethod/Currencies')) },
    ],
  },
  {
    path: '/dashboard',
    element: lazy(() => import('./Dashboard')),
  },
  {
    path: '/partners/:partnerId',
    element: lazy(() => import('./Partners/Partner')),
    permissions: [AffiliatePermissionsEnum.SUPER_ADMIN],
    routes: [
      {
        path: '',
        element: (() => <Redirect to="main" />) as FC,
      },
      { path: 'main', element: lazy(() => import('./Partners/Partner/Main')) },
      { path: 'payment-methods', element: lazy(() => import('./Partners/Partner/PaymentMethods')) },
    ],
  },
  {
    path: '/affiliates',
    element: lazy(() => import('./Affiliates')),
    permissions: [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN],
    routes: [
      { path: '', element: (() => <Redirect to="verified" />) as FC },
      {
        path: 'verified',
        element: lazy(() => import('./Affiliates/Verified')),
        permissions: [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN],
      },
      {
        path: 'pending',
        element: lazy(() => import('./Affiliates/Pending')),
        permissions: [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN],
      },
      {
        path: 'unverified',
        element: lazy(() => import('./Affiliates/Unverified')),
        permissions: [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN],
      },
    ],
  },
  {
    path: '/players',
    element: lazy(() => import('./Players')),
    permissions: [
      AffiliatePermissionsEnum.SUPER_ADMIN,
      AffiliatePermissionsEnum.ADMIN,
      AffiliatePermissionsEnum.AFFILIATE_USER,
    ],
  },
  {
    path: '/affiliates/:affiliateId',
    element: lazy(() => import('./Affiliates/Affiliate')),
    permissions: [
      AffiliatePermissionsEnum.SUPER_ADMIN,
      AffiliatePermissionsEnum.ADMIN,
      AffiliatePermissionsEnum.AFFILIATE_USER,
    ],
    routes: [
      {
        path: '',
        element: (() => <Redirect to="main" />) as FC,
      },
      { path: 'dashboard', element: lazy(() => import('./Affiliates/Affiliate/Dashboard')) },
      { path: 'main', element: lazy(() => import('./Affiliates/Affiliate/Main')) },
      { path: 'players', element: lazy(() => import('./Affiliates/Affiliate/Players')) },
      { path: 'wallet-history', element: lazy(() => import('./Affiliates/Affiliate/Payments')) },
      { path: 'affiliates', element: lazy(() => import('./Affiliates/Affiliate/SubAffiliate')) },
      { path: 'promo-codes', element: lazy(() => import('./Affiliates/Affiliate/PromoCodes')) },
    ],
  },
  {
    path: '/affiliates/:affiliateId/wallet-history/:transactionId',
    element: lazy(() => import('./Affiliates/Affiliate/WalletTransactions')),
  },
  {
    path: 'withdraws',
    element: lazy(() => import('./Withdraws')),
    permissions: [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN],
    routes: [
      { path: '', element: (() => <Redirect to="in-review" />) as FC },
      {
        path: 'in-review',
        element: lazy(() => import('./Withdraws/InReview')),
        permissions: [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN],
      },
      {
        path: 'completed',
        element: lazy(() => import('./Withdraws/Completed')),
        permissions: [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN],
      },
    ],
  },
  {
    path: '/players/:playerId',
    element: lazy(() => import('./Players/Player')),
    permissions: [
      AffiliatePermissionsEnum.SUPER_ADMIN,
      AffiliatePermissionsEnum.ADMIN,
      AffiliatePermissionsEnum.AFFILIATE_USER,
    ],
    routes: [
      {
        path: '',
        element: (() => <Redirect to="main" />) as FC,
      },
      { path: 'dashboard', element: lazy(() => import('./Players/Player/Dashboard')) },
      {
        path: 'main',
        element: lazy(() => import('./Players/Player/Main')),
        permissions: [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN],
      },
      { path: 'bets', element: lazy(() => import('./Players/Player/BetHistory')) },
      { path: 'walletHistory', element: lazy(() => import('./Players/Player/WalletHistory')) },
    ],
  },
  {
    path: '/reports',
    element: BlankOutlet,
    permissions: [
      AffiliatePermissionsEnum.SUPER_ADMIN,
      AffiliatePermissionsEnum.ADMIN,
      AffiliatePermissionsEnum.AFFILIATE_USER,
    ],
    routes: [
      { path: '', element: (() => <Redirect to="player" />) as FC },
      {
        path: 'category',
        element: lazy(() => import('./Reports/Category')),
        permissions: [
          AffiliatePermissionsEnum.SUPER_ADMIN,
          AffiliatePermissionsEnum.ADMIN,
          AffiliatePermissionsEnum.AFFILIATE_USER,
        ],
      },
      {
        path: 'provider',
        element: lazy(() => import('./Reports/Provider')),
        permissions: [
          AffiliatePermissionsEnum.SUPER_ADMIN,
          AffiliatePermissionsEnum.ADMIN,
          AffiliatePermissionsEnum.AFFILIATE_USER,
        ],
      },
      {
        path: 'affiliate',
        element: lazy(() => import('./Reports/Affiliate')),
        permissions: [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN],
      },
      {
        path: 'player',
        element: lazy(() => import('./Reports/Player')),
        permissions: [
          AffiliatePermissionsEnum.SUPER_ADMIN,
          AffiliatePermissionsEnum.ADMIN,
          AffiliatePermissionsEnum.AFFILIATE_USER,
        ],
      },
    ],
  },
  {
    path: 'tools',
    element: lazy(() => import('./Tools')),
    permissions: [
      AffiliatePermissionsEnum.SUPER_ADMIN,
      AffiliatePermissionsEnum.ADMIN,
      AffiliatePermissionsEnum.AFFILIATE_USER,
    ],
  },
  {
    path: '/settings',
    element: BlankOutlet,
    permissions: [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN],
    routes: [
      { path: '', element: (() => <Redirect to="currencies" />) as FC },
      {
        path: 'currencies',
        element: lazy(() => import('./Currencies')),
        permissions: [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN],
      },
      {
        path: 'payment-methods',
        element: lazy(() => import('./SettingsPaymentMethods')),
        permissions: [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN],
      },
      {
        path: 'domains',
        element: lazy(() => import('./Domains')),
        permissions: [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN],
      },
      {
        path: 'categories',
        element: lazy(() => import('./Categories')),
        permissions: [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN],
      },
      {
        path: 'branding',
        element: lazy(() => import('./Branding')),
        permissions: [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN],
      },
      {
        path: 'configurations',
        element: lazy(() => import('./Configuration')),
        permissions: [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN],
      },
    ],
  },
  {
    path: '/settings/payment-methods/:settingsPaymentMethodId',
    element: lazy(() => import('./SettingsPaymentMethods/SettingsPaymentMethod')),
    permissions: [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN],
    routes: [
      {
        path: '',
        element: (() => <Redirect to="info" />) as FC,
      },
      {
        path: 'info',
        element: lazy(() => import('./SettingsPaymentMethods/SettingsPaymentMethod/CustomFields')),
      },
      { path: 'currencies', element: lazy(() => import('./SettingsPaymentMethods/SettingsPaymentMethod/Currencies')) },
    ],
  },
  {
    path: '*',
    element: (() => <Redirect to="/dashboard" />) as FC,
  },
  {
    path: 'commission-plans',
    element: lazy(() => import('./CommissionPlans')),
    permissions: [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN],
  },
  {
    path: 'commission-plans/own',
    element: lazy(() => import('./CommissionPlans/createEdit')),
    permissions: [AffiliatePermissionsEnum.AFFILIATE_USER],
  },
  {
    path: 'commission-plans/create',
    element: lazy(() => import('./CommissionPlans/createEdit')),
    permissions: [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN],
  },
  {
    path: 'commission-plans/:commissionPlanId',
    element: lazy(() => import('./CommissionPlans/editPlan')),
    permissions: [AffiliatePermissionsEnum.SUPER_ADMIN, AffiliatePermissionsEnum.ADMIN],
    routes: [
      {
        path: '',
        element: (() => <Redirect to="main" />) as FC,
      },
      { path: 'main', element: lazy(() => import('./CommissionPlans/createEdit')) },
      { path: 'custom-rates', element: lazy(() => import('./CommissionPlans/customRates')) },
    ],
  },
];

export default routes;
