import {
  ActivityCardsValueType,
  AffiliateAddPromoCodeType,
  ApiResponseType,
  IAffiliate,
  IAffiliatePromoCode,
  IPaymentTransaction,
} from 'types';
import fetcher from 'utils/fetcher';
import { IAffiliateReportDTO } from 'pages/App/Reports/types';
import { IAffiliateListDTO } from 'types';

import { GET_AFFILIATES_VERIFIED, GET_REPORTS_BY_AFFILIATE, POST_PLAYER } from '../paths/constants';
import * as API_URL_CREATORS from '../paths/constants';
import { AFFILIATE_DASHBOARD_GET } from '../paths/constants';

export const createAffiliate = (model: Partial<IAffiliate>): Promise<ApiResponseType<IAffiliate>> =>
  fetcher<IAffiliate, Partial<IAffiliate>>({
    method: 'POST',
    url: POST_PLAYER,
    body: model,
  });

export const updateAffiliate = (
  id: number,
  data: Partial<IAffiliateListDTO>,
): Promise<ApiResponseType<IAffiliateListDTO>> =>
  fetcher<IAffiliateListDTO, Partial<IAffiliateListDTO>>({
    method: 'PUT',
    url: API_URL_CREATORS.PUT_AFFILIATE(id),
    body: data,
  });

export const changeAffiliatesPassword = (
  id: number,
  data: { newPassword: string },
): Promise<ApiResponseType<IAffiliateListDTO>> =>
  fetcher<IAffiliateListDTO, { newPassword: string }>({
    method: 'PUT',
    url: API_URL_CREATORS.CREATE_AFFILIATE_CHANGE_PASSWORD_PUT(id),
    body: data,
  });

export const getAffiliatesReport = (): Promise<ApiResponseType<IAffiliateReportDTO[]>> =>
  fetcher<IAffiliateReportDTO[]>({
    method: 'GET',
    url: GET_REPORTS_BY_AFFILIATE,
  });

export const getAffiliates = (): Promise<ApiResponseType<{ data: IAffiliateListDTO[]; count: number }>> =>
  fetcher<{ data: IAffiliateListDTO[]; count: number }>({
    method: 'GET',
    url: GET_AFFILIATES_VERIFIED,
    params: {
      page: '1',
      size: `${Number.MAX_SAFE_INTEGER}`,
    },
  });

export const getPendingAffiliates = (id: number): Promise<ApiResponseType<IAffiliateListDTO[]>> =>
  fetcher<IAffiliateListDTO[]>({
    method: 'GET',
    url: `affiliates/pending/${id}`,
  });

export const getAffiliatesCards = (id: number): Promise<ApiResponseType<Partial<ActivityCardsValueType>>> =>
  fetcher<Partial<ActivityCardsValueType>>({
    method: 'GET',
    url: AFFILIATE_DASHBOARD_GET(id),
  });

export const getSingleAffiliateTransaction = (id: string): Promise<ApiResponseType<IPaymentTransaction>> =>
  fetcher<IPaymentTransaction>({
    method: 'GET',
    url: API_URL_CREATORS.GET_SINGLE_AFFILIATE_TRANSACTION(id),
  });

export const resendEmail = (id: string): Promise<ApiResponseType<IAffiliateListDTO>> =>
  fetcher<IAffiliateListDTO, { newPassword: string }>({
    method: 'PUT',
    url: API_URL_CREATORS.CREATE_AFFILIATE_RESEND_EMAIL_PUT(id),
  });

export const addPromoCode = (
  data: AffiliateAddPromoCodeType & { affiliateId: number },
): Promise<ApiResponseType<IAffiliatePromoCode>> =>
  fetcher<IAffiliatePromoCode, AffiliateAddPromoCodeType & { affiliateId: number }>({
    method: 'POST',
    url: API_URL_CREATORS.CREATE_PROMO_CODE,
    body: data,
  });
