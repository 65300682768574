import * as yup from 'yup';
import type { Schema, ObjectSchema } from 'yup';
import { matchIsValidTel } from 'mui-tel-input';
import { AffiliateBetCategoryTypeEnum, TransferTypeEnum } from 'enums/entity';
import { PermissionTemplateTypeEnum } from 'enums/accessTemplates';
import { CommissionPlanType } from 'enums/commissionPlans';
import { BalanceTypeEnum } from 'enums/payment';

export const loginSchema = yup
  .object()
  .shape({
    email: yup.string().trim().required(),
    password: yup.string().trim().label('Password').required(),
  })
  .required();

export const forgotSchema = yup
  .object()
  .shape({
    email: yup.string().trim().required(),
  })
  .required();

export const setNewPasswordSchema = yup
  .object()
  .shape({
    newPassword: yup.string().trim().label('Password').required(),
    confirmPassword: yup
      .string()
      .required()
      .label('Confirm password')
      .oneOf([yup.ref('newPassword')], 'Password not match'),
  })
  .required();

export const registerAdminSchema = yup
  .object()
  .shape({
    email: yup.string().trim().email().label('Email').required(),
    username: yup.string().trim().label('Username').required(),
    password: yup.string().trim().label('Password').required(),
    confirmPassword: yup
      .string()
      .required()
      .label('Confirm password')
      .oneOf([yup.ref('password')], 'Password not match'),
  })
  .required();

export const registerSchema = yup
  .object()
  .shape({
    email: yup.string().trim().email().label('Email').required(),
    phoneNumber: yup.string().test('invalid', (value) => (value ? matchIsValidTel(value) : true)),
    username: yup.string().trim().label('Username').required(),
    currencyCode: yup.string().trim().label('Currency').required(),
    password: yup.string().trim().label('Password').required(),
    confirmPassword: yup
      .string()
      .required()
      .label('Confirm password')
      .oneOf([yup.ref('password')], 'Password not match'),
  })
  .required();

export const changePasswordSchema = yup.object().shape({
  oldPassword: yup.string().trim().required(),
  newPassword: yup.string().trim().required(),
  confirmPassword: yup
    .string()
    .trim()
    .required()
    .oneOf([yup.ref('newPassword')]),
});

export const changePasswordForUserSchema = yup.object().shape({
  newPassword: yup.string().trim().required(),
  confirmPassword: yup
    .string()
    .trim()
    .required()
    .oneOf([yup.ref('newPassword')]),
});

export const transferMoneyToUserAffiliateSchema = yup
  .object()
  .shape({
    user: yup.string().required(),
    currencyCode: yup.string().required(),
    transferType: yup.mixed<TransferTypeEnum>().oneOf([TransferTypeEnum.DEPOSIT, TransferTypeEnum.WITHDRAW]).required(),
    amount: yup.number().required(),
  })
  .required();

export const createSchemaShape = {
  username: yup.string().trim().required(),
  password: yup.string().trim().required(),
  firstName: yup.string().trim(),
  lastName: yup.string().trim(),
  email: yup.string().trim().email(),
  phoneNumber: yup.string().test('invalid', (value) => (value ? matchIsValidTel(value) : true)),
  currencyCodes: yup
    .array()
    .of(yup.string().required())
    .test('invalid', (value: string[] | undefined) => value && value?.length > 0)
    .required(),
};

export const createAffiliateSchema = yup
  .object()
  .shape({
    ...createSchemaShape,
    permissionTemplateId: yup.number().required(),
  })
  .required();

export const createPlayerSchema = yup
  .object()
  .shape({
    ...createSchemaShape,
    permissionTemplateId: yup.number(),
  })
  .required();

export const createPartnerSchema = yup
  .object()
  .shape({
    name: yup.string().required(),
    domain: yup.string().required(),
  })
  .required();

export const updatePartnerGeneralSchema = yup
  .object()
  .shape({
    name: yup.string().required(),
    domain: yup.string(),
    status: yup.boolean().required(),
    contactEmail: yup.string().trim().email(),
    referralQueryName: yup.string().label('Referral query name').required(),
  })
  .required();

export const updateAffiliateAdminGeneralSchema = yup
  .object()
  .shape({
    email: yup.string().trim().email().label('Email').required(),
    username: yup.string().trim().label('Username').required(),
    createdAt: yup.string().required().label('Reg. Date'),
  })
  .required();

export const updateAffiliateGeneralSchema = yup
  .object()
  .shape({
    email: yup.string().trim().email().label('Email').required(),
    username: yup.string().trim().label('Username').required(),
    createdAt: yup.string().required().label('Reg. Date'),
    currencyCode: yup.string().trim().label('Currency').required(),
    commissionPlan: yup.string().trim().label('Commission plan').required(),
  })
  .required();
export const updateAffiliateSettingsSchema = yup
  .object()
  .shape({
    promoCode: yup.string().label('Promo Code'),
    commissionCalculationPeriodFrom: yup.string().label('Calculation Period Start Date'),
    fromSubAffiliateCPAPercent: yup.number().label('CPA Percent'),
    fromSubAffiliateRevenuePercent: yup.number().label('Revenue Percent'),
    commissionCalculationPeriodTo: yup.string().label('Calculation Period End Date'),
    affiliateInvitationReference: yup.string().label('Invitation Reference'),
    affiliate: yup.string().optional().label('Affiliate'),
    affiliateId: yup.number().optional().label('AffiliateId'),
    status: yup.boolean().required().label('Status'),
  })
  .required();

// TODO: VALIDATION_SCHEMA_TRANSLATION
// FIXME: add translation staff
export const updatePlayerGeneralSchema = yup
  .object()
  .shape({
    firstName: yup.string().optional().label('First Name'),
    lastName: yup.string().optional().label('Last Name'),
    username: yup.string().required().label('Username'),
    phoneNumber: yup.string().optional().label('Phone Number'),
    email: yup.string().required().label('Password'),
    birthDate: yup.string().nullable().optional().label('Birth Date'),
    createdAt: yup.string().required().label('Reg. Date'),
    country: yup.string().optional().label('Country'),
    currencyCode: yup.string().label('Currency Code').required(),
  })
  .required();
export const updatePlayerSettingsSchema = yup
  .object()
  .shape({
    externalPlayerId: yup.string().required().label('External Id'),
    affiliate: yup.string().optional().label('Affiliate'),
    affiliateId: yup.number().optional().label('AffiliateId'),
    status: yup.boolean().required().label('Status'),
  })
  .required();

export const updatePartnerPlatformSchema = yup
  .object()
  .shape({
    providerId: yup.number().required(),
    meta: yup.string(),
  })
  .required();

export const createAccessTemplateSchema = yup
  .object()
  .shape({
    name: yup.string().required(),
    type: yup.mixed<PermissionTemplateTypeEnum>().required(),
    layer: yup
      .number()
      .positive()
      .integer()
      .when('type', ([type], schema) =>
        type === PermissionTemplateTypeEnum.LAYERED ? schema.required() : schema.nullable(),
      ),
    permissions: yup
      .array()
      .of(yup.mixed<boolean>())
      .test('required', (value) => value?.some((i) => i)),
    grantPermissions: yup.array().of(yup.mixed<boolean>()),
  })
  .required();

const casinoLimitTypeItemsSchema = (key: string): Schema =>
  yup.number().when('_type', ([_type], schema) => (_type === key ? schema.required() : schema.nullable()));

export const casinoLimitSchema = yup
  .object()
  .shape({
    _type: yup.string().label('Type').required(),
    amount: yup.number().label('Amount').required(),
    name: yup.string().trim().label('Name').required(),
    currency: yup.number().label('Currency').required(),
    gameId: casinoLimitTypeItemsSchema('gameId').label('Game'),
    sportId: casinoLimitTypeItemsSchema('sportId').label('Sport'),
    categoryId: casinoLimitTypeItemsSchema('categoryId').label('Category'),
    providerId: casinoLimitTypeItemsSchema('providerId').label('Provider'),
  })
  .required();

export const tournamentSchema = yup
  .object()
  .shape({
    category: yup.string().trim().label('category').required(),
    startDate: yup.string().trim().label('startDate').required(),
    endDate: yup.string().trim().label('endDate').required(),
    prizePool: yup.number().label('prizePool').required(),
    translations: yup
      .array()
      .of(
        yup.object().shape({
          iconUrl: yup.string().trim().label('iconUrl').required(),
          name: yup.string().trim().label('name').required(),
          description: yup.string().trim().label('description').required(),
          languageId: yup.number().label('languageId').required(),
        }),
      )
      .required(),
    tournamentTemplateId: yup.number().label('tournamentTemplateId').required(),
  })
  .required();

export const createYupSchema = (
  fields: { key: string; isRequired: boolean }[],
  existSchema: ObjectSchema<Schema>,
): Schema => {
  const generatedSchema = fields.reduce((schema, field) => {
    const validation = yup
      .string()
      .trim()
      .label(field.key)
      .test(field.key, `${field.key} is required`, function (value) {
        if (field.isRequired) {
          return !!value;
        }
        return true;
      });

    return { ...schema, [field.key]: validation };
  }, {});

  return (existSchema ?? yup.object()).shape(generatedSchema).required();
};

export const createDomainSchema = yup
  .object()
  .shape({
    name: yup.string().trim().label('Name').required(),
    domain: yup.string().trim().label('Domain').required(),
    description: yup.string().trim().label('Description').nullable(),
  })
  .required();

export const createCategorySchema = yup
  .object()
  .shape({
    name: yup.string().trim().label('Name').required(),
    externalId: yup.string().trim().label('External id').required(),
    type: yup
      .mixed<AffiliateBetCategoryTypeEnum>()
      .oneOf([AffiliateBetCategoryTypeEnum.CASINO, AffiliateBetCategoryTypeEnum.SPORT])
      .label('Type')
      .required(),
  })
  .required();

export const affiliateApproveSchema = yup
  .object()
  .shape({
    commissionPlan: yup.string().required().label('Commission Plan'),
    commissionPlanTypes: yup.string().required().label('Commission Plan Types'),
  })
  .required();

export const affiliateAddPromoCodeSchema = yup
  .object()
  .shape({
    code: yup.string().required().label('Code'),
    description: yup.string().label('Description'),
  })
  .required();

export const createCustomFieldSchema = yup
  .object()
  .shape({
    key: yup
      .string()
      .label('Key')
      .required()
      .test('invalid', (value) => (value ? /^([a-zA-Z]+)$/.test(value) : false)),
    hint: yup.string().label('Hint').required(),
    isRequired: yup.boolean().label('Required').required(),
  })
  .required();

export const createCurrencySchema = yup
  .object()
  .shape({
    code: yup.string().trim().label('Code').required(),
    isEnabled: yup.boolean().label('Is enabled').required(),
  })
  .required();

export const createCustomRateSchema = yup
  .object()
  .shape({
    currencyCode: yup.string().trim().label('Code').required(),
    rate: yup.number().label('Rate').required(),
  })
  .required();

export const createCommissionPlanSchema = yup
  .object()
  .shape({
    name: yup.string().trim().label('Name').required(),
    type: yup.string().label('Type').required(),

    calculateEvery: yup
      .number()
      .label('Calculation Periud')
      .when('type', ([type], schema) =>
        type === CommissionPlanType.REVENUE || type === CommissionPlanType.BOTH ? schema.required() : schema.nullable(),
      ),
    minDepositAmount: yup
      .number()
      .label('Min. Deposit Amount')
      .when('type', ([type], schema) => (type === CommissionPlanType.CPA ? schema.required() : schema.nullable())),
    revenuePlans: yup
      .array()
      .of(
        yup
          .object()
          .shape({
            ggrFrom: yup.number().label('From').required(),
            ggrTo: yup.number().label('To').required(),
            percent: yup.number().label('Percent').required(),
            categoryId: yup.number().label('Category Id').required(),
            currencyCode: yup.string().label('Currency Code').required(),
          })
          .required(),
      )
      .optional()
      .nullable(),
    cpaPlans: yup
      .array()
      .of(
        yup
          .object()
          .shape({
            countFrom: yup.number().label('From Count').required(),
            countTo: yup.number().label('To Count').required(),
            amount: yup.number().label('Amount').required(),
            currencyCode: yup.string().label('Currency Code').required(),
          })
          .required(),
      )
      .optional()
      .nullable(),
  })
  .required();

export const createPaymentMethodSchema = yup
  .object()
  .shape({
    name: yup.string().required(),
    logo: yup.string().required(),
  })
  .required();

export const createPaymentMethodCustomFieldSchema = yup
  .object()
  .shape({
    name: yup.string().required(),
    isRequired: yup.boolean().required(),
  })
  .required();

export const transferPlayerMoneySchema = yup
  .object()
  .shape({
    type: yup.mixed<BalanceTypeEnum>().oneOf([BalanceTypeEnum.CPA, BalanceTypeEnum.REVENUE]).required(),
    amount: yup.number().positive().required(),
    methodId: yup.string().label('Payment Method').required(),
  })
  .required();

export const confirmWithdrawSchema = yup
  .object()
  .shape({
    confirmationCode: yup.string().trim().label('Confirmation Code'),
  })
  .required();
